@media (max-width: 1000px) {

    .Different_Info {
        height: 100vh;
        position: relative;
        top: 3.5%;
        width: 90%;
        min-height: 170vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-top: 110vh;
        text-align: center;
        /* background-color: rebeccapurple; */
    }

    .Different_Info .different_title_box {
        width: 80%;
        height: 30%;
        /* background-color: red; */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        top: 2%;
        /* background-color: #495AFF; */
    }

    .Different_Info .different_title_box .title {
        margin-bottom: 5%;
    }

    .Different_Info .infoContiner {
        width: 100%;
        height: 40%;
        /* background-color: aqua; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
        top: 17%;
    }

    .Different_Info .infoContiner .icon {
        min-width: 45%;
        height: 100%;
        width: 100%;
        /* background-color: red; */
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        pointer-events: none;
        display: none;
    }

    .Different_Info .infoContiner.right .icon {
        position: relative;
        left: 0%;
        bottom: 30%;
    }

    .Different_Info .infoContiner.right .textbox .title {
        margin-right: 13%;
    }

    .Different_Info .infoContiner.infoContiner.right .textbox .subTitle {
        position: relative;
        /* bottom: 30%; */
        margin-left: 10%;
    }

    .Different_Info .infoContiner.right .textbox .btnBox {
        position: relative;
        right: 5%;
    }

    .Different_Info .infoContiner .textbox {
        width: 100%;
        height: 50%;
        /* background-color: blanchedalmond; */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .Different_Info .infoContiner.right .textbox {
        position: relative;
        /* margin-left: 5%; */
        bottom: 60%;
        height: 55%;
    }

    .Different_Info .infoContiner.left {
        position: relative;
        top: 15rem;
        right: 5%;
        
    }
    .Different_Info .infoContiner.right {
        position: relative;
        top: 32rem;
        
    }


    .Different_Info .infoContiner.left .textbox {
        position: relative;
        bottom: 30%;
        height: 60%;
    }

    .Different_Info .infoContiner.left .textbox .title {
        margin-bottom: 0%;
    }

    .Different_Info .infoContiner.left .textbox .subTitle {
        position: relative;
        /* bottom: 30%; */
        margin-left: 15%;
    }

    .Different_Info .infoContiner .textbox .btnBox {
        min-width: 70%;
        width: 100%;
        min-height: 10%;
        height: 10%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        /* background-color: bisque; */
    }

    .Different_Info .infoContiner .textbox .btnBox .CTA {
        width: 100%;
        min-height: 100%;
        height: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color: royalblue; */
        border-radius: 60px;
        color: white;
        font-size: 18px;
        font-weight: 800;
        background-color: black;
        text-decoration: none;
        margin: 10%;
    }
    
    .Different_Info .infoContiner .textbox .btnBox .alternate {
       font-size: 18px;
       font-weight: 800;
       background: linear-gradient(132.47deg, #0ACFFE -1.24%, #495AFF 62.82%, #495AFF 62.82%);
       -webkit-background-clip: text;
       -webkit-text-fill-color: transparent;
       background-clip: text;
       text-fill-color: transparent;
       font-style: italic;
       text-align: center;
       margin-left: 30%;

    }   
    
}

@media (min-width: 1000px) {

    .Different_Info .processIcon {
        display: none;
    }
    
    .Different_Info .resultsIcon {
        display: none;
    }

}

.Different_Info .processIcon {
    position: absolute;
    width: 100%;
    height: 20%;
    background-image: url(./icons/client_process_svg.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    top: 113%;
}

.Different_Info .resultsIcon {
    position: absolute;
    width: 100%;
    height: 20%;
    background-image: url(./icons/pay_for_results_svg.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    top: 60%;
}

.Different_Info {
    width: 90%;
    min-height: 170vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.Different_Info .different_title_box {
    min-width: 50%;
    height: 12%;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.Different_Info .different_title_box .title {
    font-size: 50px;
    font-weight: 800;
}

.Different_Info .different_title_box .subTitle {
    font-size: 17px;
    font-weight: 700;
    text-align: center;
    line-height: 35px;
    color: #5C5C5C;
    width: 80%;
}

.Different_Info .bold {
   color: black;
   font-style: italic;
   font-weight: 900;
}

.Different_Info .infoContiner {
    min-width: 100%;
    min-height: 40%;
    /* background-color: aqua; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Different_Info .infoContiner.right {
    flex-direction: row-reverse;
}


.Different_Info .infoContiner .icon {
    min-width: 45%;
    height: 100%;
    /* background-color: red; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.Different_Info .infoContiner.left .icon {
    background-image: url(./icons/pay_for_results_svg.svg);
}

.Different_Info .infoContiner.right .icon {
    background-image: url(./icons/client_process_svg.svg);
    position: relative;
    right: 5%;
}

.Different_Info .infoContiner .textbox {
    min-width: 50%;
    min-height: 10%;
    height: 50%;
    /* background-color: blanchedalmond; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}


.Different_Info .infoContiner.left .textbox {
    position: relative;
    left: 5%;
 }
 

.Different_Info .infoContiner.right .textbox {
   position: relative;
   left: 5%;
}

.Different_Info .infoContiner .textbox .title {
    font-size: 40px;
    font-weight: 800;
}

.Different_Info .infoContiner .textbox .subTitle {
    font-size: 17px;
    width: 80%;
    line-height: 35px;
    color: #5C5C5C;
    font-weight: 600;
    width: 70%;
}

.Different_Info .infoContiner .textbox .btnBox {
    min-width: 70%;
    /* min-height: 10%; */
    height: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* background-color: bisque; */
}

.Different_Info .infoContiner .textbox .btnBox .CTA {
    min-width: 65%;
    min-height: 10%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: royalblue; */
    border-radius: 60px;
    color: white;
    font-size: 18px;
    font-weight: 800;
    background-color: black;
    text-decoration: none;
}

.Different_Info .infoContiner .textbox .btnBox .alternate {
   font-size: 18px;
   font-weight: 800;
   background: linear-gradient(132.47deg, #0ACFFE -1.24%, #495AFF 62.82%, #495AFF 62.82%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   background-clip: text;
   text-fill-color: transparent;
   font-style: italic;
}

