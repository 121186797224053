
body {
    overflow-x:hidden;
    /* overflow-y: hidden; */
    scroll-behavior: smooth;
}

.app {
    display: grid;
    grid-template-columns: repeat(4, 100%);
    will-change: transform;
    align-content: center;
    overflow-x: auto;
    scroll-snap-coordinate: 0 0;
    scroll-snap-points-x: repeat(100%);
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar {
  display: none;
}

  .app .page {
    /* height: 100vh; */
    scroll-snap-align: start;
  }

.pageTitle {
    font-family: appBold;
    font-size: 25px;
    font: 500;
    margin: 0rem 0rem 0rem 2rem;
}

.categoryTitle {
    font-family: appBold;
    font-size: 20px;
    font: 500;
    margin: 0rem 0rem 0rem 2rem;
    color: rgb(215, 214, 214);
}