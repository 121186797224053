@media (max-width: 1000px) {
    .NavBar {
    /* height: 20vh; */
    /* background-color: aquamarine; */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 30%;
    margin-top: 5%;
    }

    .NavBar .pages_container {
        width: 100%;
        /* background-color: blue; */
        flex-direction: column;
    }

    .NavBar .app_logo {
        width: 100%;
        /* background-color: blue; */
        left: 0%;
    }

    .NavBar .CTA_button {
        width: 100%;
        height: 50%;
    }


    .NavBar .pages_container .page_button h1 {
        font-size: 12px;
    }
    
}

.NavBar {
    min-width: 90%;
    min-height: 17vh;
    /* background-color: aquamarine; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    flex-wrap: wrap;
}

.NavBar .app_logo {
    min-width: 15%;
    min-height: 55%;
    /* background-color: red; */
    background-image: url(../images/DL-Media-Logo-website\ \(1\).png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    right: 2%;
}

.NavBar .pages_container {
    min-width: 30%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* background-color: whitesmoke; */
}

.NavBar .pages_container .page_button {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: whitesmoke; */
    text-decoration: none;
    color: black;
}

.NavBar .pages_container .page_button h1 {
    font-size: 17px;
font-style: normal;
font-weight: 800;
line-height: 29px;
display: flex;
align-items: center;
text-align: center;
text-transform: capitalize;
}

.NavBar .CTA_button {
    min-width: 15%;
    min-height: 40%;
    border: 5px solid;
    background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(132.47deg, #0ACFFE -1.24%, #495AFF 62.82%, #495AFF 62.82%) border-box;
border: 4px solid transparent;
border-radius: 50px;
text-decoration: none;
display: flex;
justify-content: center;
align-items: center;
align-self: center;
}

.NavBar .CTA_button h1 {
    background: linear-gradient(132.47deg, #0ACFFE -1.24%, #495AFF 62.82%, #495AFF 62.82%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
font-size: 17px;
font-style: normal;
font-weight: 900;
line-height: 29px;
display: flex;
align-items: center;
text-align: center;
text-transform: capitalize;
}