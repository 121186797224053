@media (max-width: 1000px) {

    .Meet_Founders {
        width: 90%;
        height: 250vh;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
        bottom: 5%;
    }


.Meet_Founders .textBox {
    min-width: 50%;
    width: 100%;
    max-height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 5rem;
}

.Meet_Founders .textBox .title {
    font-size: 45px;
    text-align: center;
    margin-top: 5%;
}

.Meet_Founders .textBox .desc {
    font-size: 17px;
    color: #5C5C5C;
    line-height: 35px;
    position: relative;
    /* bottom: 3%; */
    text-align: center;
    top: 0%;
    position: relative;
    left: 5%;
}

.Meet_Founders .foundersBox {
    min-width: 45%;
    width: 90%;
    height: 30%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    /* top: 12%; */
    left: 6%;
    margin-bottom: 30rem;
    /* background-color: #0ACFFE; */
}


.Meet_Founders .foundersBox .founderColm {
    min-width: 48%;
    width: 100%;
    height: 45%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* background-color: blue; */
    border-radius: 30px;
}

.Meet_Founders .textBox .CTA_button {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    border-radius: 60px;
    text-decoration: none;
    position: relative;
    top: 2rem;
}

}

@media (min-width: 1000px) {

    .Meet_Founders .foundersBox {
        min-width: 45%;
        min-height: 20%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }

}

.Meet_Founders {
    width: 90%;
    min-height: 60vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Meet_Founders .textBox {
    min-width: 50%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.Meet_Founders .textBox .title {
    font-size: 45px;
}

.Meet_Founders .textBox .title .select {
    background: linear-gradient(132.47deg, #0ACFFE -1.24%, #495AFF 62.82%, #495AFF 62.82%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.Meet_Founders .textBox .desc {
    font-size: 17px;
    color: #5C5C5C;
    line-height: 35px;
    position: relative;
    bottom: 3%;
    margin: 5%;
    margin-left: 0%;
    margin-right: 10%;
}

.Meet_Founders .textBox .desc .select {
    color: black;
    font-weight: 800;
    font-style: italic;
}

.Meet_Founders .textBox .CTA_button {
    min-width: 60%;
    min-height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    border-radius: 60px;
    text-decoration: none;
}

.Meet_Founders .textBox .CTA_name {
    font-size: 18px;
    color: white;
    font-weight: 800;
}

.Meet_Founders .foundersBox {
    min-width: 45%;
    min-height: 20%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Meet_Founders .foundersBox .founderColm {
    min-width: 48%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* background-color: blue; */
    border-radius: 30px;
}

.Meet_Founders .foundersBox .founderColm .pic {
    width: 100%;
    height: 70%;
    /* background-color: aqua; */
    border-radius: 30px 30px 15px 15px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.Meet_Founders .foundersBox .founderColm .pic.luke {
    background-image: url(./icons/lukevorbau.png);
}

.Meet_Founders .foundersBox .founderColm .pic.david {
    background-image: url(./icons/davidstolyar.png);
}

.Meet_Founders .foundersBox .founderColm .name {
    font-size: 20px;
    padding: 10%;
    font-weight: 800;
}

.Meet_Founders .foundersBox .founderColm .company_title {
    font-size: 17px;
    position: relative;
    bottom: 3%;
    color: #6D6D6D;
}