@media (max-width: 1000px) {

    .CTA_Header {
        width: 100%;
        height: 80vh;
        /* background-color: red; */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
        /* top: 0.1%; */
        bottom: 2%;
    }

    .CTA_Header .titleBox {
        min-width: 50%;
        width: 100%;
        height: 60%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;
        left: 12%;
    }

    .CTA_Header .icon {
       display: none;
    }


.CTA_Header .titleBox .hero_title {
    position: relative;
    bottom: 15%;
}


.CTA_Header .titleBox .CTA_button {
    min-width: 50%;
    width: 80%;
    height: 25%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border-radius: 60px;
    position: relative;
    right: 0%;
}

.CTA_Header .titleBox .hero_desc {
    margin-bottom: 7%;
}

}

@media (min-width: 1000px) {

    .CTA_Header .CTA_icon {
        display: none;
    }

    .CTA_Header {
        position: relative;
        top: 0%;
    }

}

.CTA_Header .CTA_icon {
    position: absolute;
    width: 100%;
    height: 35%;
    background-image: url(./icons/DL_Media_CTA_svg.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    top: 95%;
}

.CTA_Header {
    width: 100%;
    min-height: 70vh;
    /* background-color: red; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 5%;
}

.CTA_Header .icon {
    width: 37%;
    height: 100%;
    /* background-color: azure; */
    background-image: url(./icons/DL_Media_CTA_svg.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    left: 2%;
}

.CTA_Header .titleBox {
    min-width: 50%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    left: 12%;
}

.CTA_Header .titleBox .hero_title {
    width: 70%;
    font-size: 40px;
    font-weight: 800;
}

.CTA_Header .titleBox .hero_desc {
    width: 60%;
    font-size: 17px;
    font-weight: 600;
    position: relative;
    bottom: 5%;
    line-height: 30px;
    color: #5C5C5C;
}

.CTA_Header .titleBox .CTA_button {
    min-width: 50%;
    min-height: 22%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border-radius: 60px;
}

.CTA_Header .titleBox .CTA_button h1 {
    font-size: 18px;
    font-weight: 800;
    color: white;
}