@media (max-width: 1000px) {

    .Accomplishments .dividerBottom {
        opacity: 0;
    }

    .Accomplishments .title_box .dividerTop {
        opacity: 0;
    }

    .Accomplishments .title_box {
        text-align: center;
        position: relative;
        left: 7%;
    }

    .Accomplishments {
        width: 90%;
        min-height: 50vh;
        height: 70vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        /* background-color: rebeccapurple; */
        position: relative;
        pointer-events: none;
        top: 0.5%;
    }

    .Accomplishments .accomplishment_container {
        width: 100%;
        height: 80%;
        /* background-color: red; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    .Accomplishments .accomplishment_colm.revenue {
        position: relative;
        left: 6%;
    }

    .Accomplishments .accomplishment_colm.spend {
        position: relative;
        left: 7%;
    }

    .Accomplishments .accomplishment_colm.clients {
        position: relative;
        left: 17%;
    }

    .Accomplishments .accomplishment_colm .subTitle {
        font-size: 17px;
        font-weight: 600;
    }


}

.Accomplishments {
    width: 90%;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* background-color: rebeccapurple; */
}

.Accomplishments .title_box {
    width: 100%;
    height: 10%;
    /* background-color: aqua; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 22px;
}

.Accomplishments .title_box .dividerTop {
    width: 67%;
    height: 2px;
    background-color: #e3e3e3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 360px;
    position: relative;
    top: 5%;
}

.Accomplishments .accomplishment_container {
    width: 100%;
    height: 80%;
    /* background-color: red; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Accomplishments .accomplishment_colm {
    min-width: 30%;
    max-height: 30%;
    /* background-color: blue; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.Accomplishments .accomplishment_colm .subTitle {
    font-size: 17px;
    font-weight: 600;
}

.Accomplishments .accomplishment_colm .Title {
    font-size: 45px;
    font-weight: 800;
}

.Accomplishments .accomplishment_colm .Title .select {
    font-weight: 800;
    background: linear-gradient(132.47deg, #0ACFFE -1.24%, #495AFF 62.82%, #495AFF 62.82%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.Accomplishments .dividerBottom {
    width: 100%;
    height: 2px;
    background-color: #e3e3e3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 360px;
    opacity: 0;
}