@media (max-width: 1000px) {

    .Testimonials {
        width: 90%;
        height: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        /* background-color: rebeccapurple; */
        position: relative;
        top: 5.5%;
    }
  
    .Testimonials .title_box .dividerTop {
        opacity: 0;
    }

    .Testimonials .title_box {
       text-align: center;
       position: relative;
       left: 17%;
    }

    .Testimonials .testimonial_container {
        width: 100%;
        height: 80%;
        /* background-color: gray; */
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        bottom: 2%;
        flex-wrap: wrap;
    }

    .Testimonials .testimonial_container .testimonialBox {
        min-width: 32%;
        max-height: 90%;
        height: 65%;
        /* background-color:blue; */
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        border-radius: 20px;
        width: 100%;
        margin-bottom: 30%;
    }

    .Testimonials .dividerBottom {
        opacity: 0;
    }
    
}

.Testimonials {
    width: 90%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* background-color: rebeccapurple; */
}

.Testimonials .title_box {
    width: 100%;
    height: 10%;
    /* background-color: aqua; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 22px;
}

.Testimonials .title_box .dividerTop {
    width: 80%;
    height: 2px;
    background-color: #e3e3e3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 360px;
    position: relative;
    top: 5%;
}

.Testimonials .testimonial_container {
    width: 100%;
    height: 80%;
    /* background-color: gray; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    bottom: 2%;
}

.Testimonials .testimonial_container .testimonialBox {
    min-width: 32%;
    max-height: 90%;
    /* background-color:blue; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

.Testimonials .testimonial_container .testimonialBox .quote {
    width: 80%;
    height: 60%;
    /* background-color: gold; */
    font-size: 19px;
    line-height: 30px;
    font-weight: 500;
    text-align: center;
}

.Testimonials .testimonial_container .testimonialBox .author {
    font-size: 22px;
    position: relative;
    font-weight: 800;
    /* bottom: 2%; */
    /* left: 8%; */
    /* background-color: aqua; */
    width: 80%;
    text-align: center;
    margin-bottom: 10%;
}

.Testimonials .testimonial_container .testimonialBox .authorBox .authorPic.COO {
   background-image: url(./icons/COO\,\ TripAdvisor.jpeg);
}

.Testimonials .testimonial_container .testimonialBox .authorBox .authorPic.CMO {
    background-image: url(./icons/CMO\,\ AirBnb\ .jpeg);
}

 .Testimonials .testimonial_container .testimonialBox .authorBox .authorPic.VP {
    background-image: url(./icons/VP\ of\ Marketing\,\ Masterclass\ .png);
}

.Testimonials .dividerBottom {
    width: 100%;
    height: 2px;
    background-color: #e3e3e3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 360px;
    opacity: 0;
}