@media (max-width: 1000px) {

    .Our_Clients {
        min-width: 90%;
        min-height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        /* background-color: rebeccapurple; */
        position: relative;
        top: 4%;
    }
    
    .Our_Clients .title_box .dividerTop {
        opacity: 0;        
    }

    .Our_Clients .title_box {
       text-align: center;
       position: relative;
       left: 30%;
    }

    .Our_Clients {
        height: 50vh;
        position: relative;
        bottom: 0.5%;
    }

    .Our_Clients .clients_container {
        min-width: 100%;
        min-height: 80%;
        /* background-color:red; */
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;  
    }
    
    .Our_Clients .clients_container .client {
        min-width: 30%;
        width: 50%;
        height: 5%;
        /* background-color:blue; */
        display: flex;
        justify-content: space-between;
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
    }

    .Our_Clients .dividerBottom {
        opacity: 0;
    }
    

}

.Our_Clients {
    min-width: 90%;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* background-color: rebeccapurple; */
}

.Our_Clients .title_box {
    min-width: 100%;
    min-height: 10%;
    /* background-color: aqua; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 22px;
}

.Our_Clients .title_box .dividerTop {
    min-width: 80%;
    min-height: 2px;
    background-color: #e3e3e3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 360px;
    position: relative;
    top: 5%;
}

.Our_Clients .clients_container {
    min-width: 100%;
    min-height: 80%;
    /* background-color:red; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;  
}

.Our_Clients .clients_container .client {
    min-width: 30%;
    height: 45%;
    /* background-color:blue; */
    display: flex;
    justify-content: space-between;
    background-position: center;
    background-size: 60%;
    background-repeat: no-repeat;
}

.Our_Clients .clients_container .client.Airbnb {
   background-image: url(./icons/airbnb_svg.svg);
}

.Our_Clients .clients_container .client.Tripadvisor {
    background-image: url(./icons/tripadvisor_svg.svg);
}

.Our_Clients .clients_container .client.Fitbit {
    background-image: url(./icons/fitbit_svg.svg);
}

.Our_Clients .clients_container .client.TwistedTea {
    background-image: url(./icons/twistedtea_svg.svg);
}

.Our_Clients .clients_container .client.Trustpilot {
    background-image: url(./icons/trustpilot_svg.svg);
}

.Our_Clients .clients_container .client.LaFitness {
    background-image: url(./icons/LAfitness_svg.svg);
}

/* temp items css */

.Our_Clients .clients_container .client.planetbake {
    background-image: url(./icons/planetbake.png);
 }
 
 .Our_Clients .clients_container .client.zenduce {
     background-image: url(./icons/zenduce.png);
 }
 
 .Our_Clients .clients_container .client.vetriMax {
     background-image: url(./icons/VetriMax.png);
 }
 
 .Our_Clients .clients_container .client.kidodido {
     background-image: url(./icons/kidodido.png);
     background-size: 35%;
 }
 
 .Our_Clients .clients_container .client.farmfluence {
     background-image: url(./icons/farmfluence.png);
 }
 
 .Our_Clients .clients_container .client.ambrosia {
     background-image: url(./icons/ambrosia.png);
 }

.Our_Clients .dividerBottom {
    min-width: 100%;
    min-height: 2px;
    background-color: #e3e3e3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 360px;
    opacity: 0;
}