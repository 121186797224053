@media (max-width: 1000px) {

    .Footer .textbox .company_logo {
        width: 30%;
    }

    .Footer .data_container .dataText {
        opacity: 0;
    }

    .Footer .data_container .dataText.inc {
        opacity: 1;
    }

    .Footer .data_container .dataText.email {
        opacity: 1;
    }
}

.Footer {
    background-color: #0acdfe16;
    width: 100%;
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.Footer .textbox {
    width: 90%;
    height: 70%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   /* background-color: bisque; */
}

.Footer .textbox .company_logo {
    min-width: 10%;
    height: 40%;
   background-image: url(../images/DL-Media-Logo-website\ \(1\).png);
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
   /* background-color: aqua; */
   margin-bottom: 1%;
}

.Footer .textbox .desc {
    font-size: 16px;
}

.Footer .data_container {
    width: 90%;
    height: 30%;
    /* background-color: aqua; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Footer .data_container .mailBox {
    width: 16%;
    height: 100%;
    /* background-color: red; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Footer .data_container .mailBox .logo {
    width: 15%;
    height: 100%;
    background-image: url(./icons/DL_Media_footer_mail_svg.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.Footer .data_container .dataText {
    font-size: 15px;
    color: black;
    text-decoration: none;
    font-weight: 600;
}