@media (max-width: 1000px) {

    .About_Us_Header {
        width: 80%;
        height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        top: 1%;
    }

    .About_Us_Header .btnBox {
        width: 100%;
        height: 45%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        opacity: 0;
    }

    .About_Us_Header .btnBox {
    width: 90%;
    height: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

}

.About_Us_Header {
    width: 70%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.About_Us_Header .title {
    font-size: 45px;
    color: black;
}

.About_Us_Header .title .select {
    background: linear-gradient(132.47deg, #0ACFFE -1.24%, #495AFF 62.82%, #495AFF 62.82%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.About_Us_Header .subTitle {
    font-size: 17px;
    color: #545454;
    height: 40%;
    line-height: 35px;
    text-align: center;
}

.About_Us_Header .subTitle .select {
    color: black;
    font-weight: 800;
    font-style: italic;
}

.About_Us_Header .btnBox {
    min-width: 60%;
    height: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.About_Us_Header .btnBox .item {
    width: 100%;
    height: 25%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: linear-gradient(#fff, #fff) padding-box, linear-gradient(132.47deg, #0ACFFE -1.24%, #495AFF 62.82%, #495AFF 62.82%) border-box;
    border: 4px solid transparent;
    border-radius: 60px;
}

.About_Us_Header .btnBox .item .desc {
    font-size: 17px;
    font-weight: 800;
    color: black;
}

.About_Us_Header .btnBox .item .iconBox {
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
    position: absolute;
    background-color: white;
    border-radius: 360px;
    right: 28%;
    margin-bottom: 5%;
}

.About_Us_Header .btnBox .item .iconBox .icon{
    width: 1.5rem;
    height: 1.5rem;
    background-image: url(./icons/checklist_svg.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}