@media (max-width: 1000px) {



.Free_Audit .title {
    font-size: 40px;
    font-weight: 800;
    color: black;
    text-align: center;
    left: 0%;
    position: relative;
    bottom: 2%;
    margin-top: 10%;
}



.Free_Audit .steps_container {
    width: 80%;
    /* background-color: aqua; */
    min-height: 50vh;
    height: 80vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    bottom: 3%;
}

.Free_Audit .steps_container .dataItem {
    min-width: 47%;
    width: 100%;
    background-color: white;
    height: 3%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
    min-height: 18%;
    height: 30%;
    margin-bottom: 10%;
}

.Free_Audit .discovery_btn {
    width: 90%;
    background-color: black;
    height: 14vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 360px;
    text-decoration: none;
    box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.25);
    position: relative;
    top: 9%;
    margin-bottom: 20%;
}

.Free_Audit .steps_container .dataItem .iconBox {
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: white;
    border-radius: 360px;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
    margin-right: -60%;
    margin-top: -25%;
}


}

.Free_Audit {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.Free_Audit .title {
    font-size: 40px;
    font-weight: 800;
    color: black;
}

.Free_Audit .title .select {
    background: linear-gradient(132.47deg, #0ACFFE -1.24%, #495AFF 62.82%, #495AFF 62.82%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.Free_Audit .steps_container {
    width: 80%;
    /* background-color: aqua; */
    min-height: 50vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.Free_Audit .steps_container .dataItem {
    min-width: 47%;
    background-color: white;
    height: 18%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
}

.Free_Audit .steps_container .dataItem .name {
    font-size: 15px;
    font-weight: 500;
    width: 90%;
}

.Free_Audit .steps_container .dataItem .name .bold {
    font-weight: 800;
}

.Free_Audit .steps_container .dataItem .iconBox {
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: white;
    border-radius: 360px;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
    margin-left: 37%;
    margin-bottom: 3%;
}

.Free_Audit .steps_container .dataItem .iconBox .number {
    font-size: 20px;
    background: linear-gradient(132.47deg, #0ACFFE -1.24%, #495AFF 62.82%, #495AFF 62.82%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 900;
}

.Free_Audit .discovery_btn {
    min-width: 35%;
    background-color: black;
    min-height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 360px;
    text-decoration: none;
    box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.25);
}

.Free_Audit .discovery_btn .name {
    color: white;
    font-weight: 900;
    font-size: 18px;
}

