@media (max-width: 1000px) {

    .Hero_Header {
        height: 70vh;
        /* background-color: aqua; */
        margin-top: 10%;
    }
    
    .Hero_Header .textbox {
        width: 90%;
    }

    .Hero_Header {
        flex-wrap: wrap;
    }

    .Hero_Header .hero_img {
        width: 90%;
        position: relative;
        bottom: 57%;
        pointer-events: none;
        display: none;
    }

    .Hero_Header .textbox .hero_title {
        font-size: 20px;
        width: 100%;
        position: relative;
        bottom: 4vh;
    }
    
    .Hero_Header .textbox .btn_box {
    height: 4rem;
    /* background-color: red; */
    position: relative;
    /* bottom: 10%; */
    /* top: 5vhx; */
}


.Hero_Header .textbox .btn_box .CTA {
    height: 45%;
    width: 100%;
}

.Hero_Header .textbox .btn_box {
    flex-wrap: wrap;
    position: relative;
    bottom: 2%;
    right: 5%;
}

.Hero_Header .textbox .btn_box .alternate {
    text-align: center;
    width: 100%;
    position: relative;
    margin-right: 10%;
    margin-top: 5%;
}

.Hero_Header .textbox .check_list {
    opacity: 0;
 }

 .Hero_Header .textbox .hero_desc {
   position: relative;
   /* top: 2vh; */
   bottom: 2vh;
}

}

@media (min-width: 1000px) {

    .Hero_Header .heroIcon {
        display: none;
    }

}

.Hero_Header .heroIcon {
    position: absolute;
    width: 100%;
    height: 50%;
    background-image: url(./icons/DLmedia-homepage-header.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    top: 90%;
}

.Hero_Header {
    min-width: 90%;
    min-height: 85vh;
    /* background-color: rebeccapurple; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 0.5%;
}

.Hero_Header .hero_img {
    min-width: 50%;
    min-height: 100%;
    /* background-color: aqua; */
    background-image: url(./icons/DLmedia-homepage-header.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    left: 0%;
}

.Hero_Header .textbox {
    min-width: 50%;
    height: 90%;
    /* background-color: royalblue; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    left: 5%;
}

.Hero_Header .textbox .hero_title {
    font-size: 45px;
    width: 100%;
}

.Hero_Header .textbox .hero_title .select {
    font-size: 45px;
    width: 100%;
    font-weight: 700;
    background: linear-gradient(132.47deg, #0ACFFE -1.24%, #495AFF 62.82%, #495AFF 62.82%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.Hero_Header .textbox .hero_desc {
    font-size: 18px;
    width: 100%;
    line-height: 35px;
    color: #5C5C5C;
}

.Hero_Header .textbox .hero_desc .highlight {
    color: black;
    font-weight: 800;
    font-style: italic;
}

.Hero_Header .textbox .btn_box {
    min-width: 90%;
    min-height: 11.5%;
    /* background-color: red; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2%;
    margin-top: 2%;
}

.Hero_Header .textbox .btn_box .CTA {
    min-width: 50%;
    min-height: 100%;
    /* background: linear-gradient(132.47deg, #0ACFFE -1.24%, #495AFF 62.82%, #495AFF 62.82%); */
    background-color: black;
    /* opacity: 0.1; */
    box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.25);
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 14px;
    color: white;
}

.Hero_Header .textbox .btn_box .alternate {
    text-decoration: none;
    font-size: 14px;
    position: relative;
    left: 5%;
    font-style: italic;
    background: linear-gradient(132.47deg, #0ACFFE -1.24%, #495AFF 62.82%, #495AFF 62.82%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}

.Hero_Header .textbox .check_list {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: flex-start;
   height: 25%;
   width: 100%;
}

.Hero_Header .textbox .check_list .check_colm {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 30%;
    width: 100%;
    /* background-color: red; */
}

.Hero_Header .textbox .check_list .check_colm .check_img {
    height: 100%;
    width: 3.5%;
    /* background-color: #495AFF; */
    background-image: url(./icons/checklist_svg.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.Hero_Header .textbox .check_list .check_colm .check_text {
    height: 100%;
    width: 92%;
    /* background-color: aqua; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
