@media (max-width: 1000px) {

    .Case_Hero_Header {
        width: 90%;
        /* background-color: rebeccapurple; */
        display: flex;
        height: 100vh;
        justify-content: center;
        align-items: center;
        position: relative;
        bottom: 0.5%;
        pointer-events: none;
    }

    .Case_Hero_Header .textbox {
        min-width: 50%;
        width: 90%;
        height: 75%;
        /* background-color: royalblue; */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }

    .Case_Hero_Header .textbox .CTA {
        width: 100%;
        height: 15%;
        /* background: linear-gradient(132.47deg, #0ACFFE -1.24%, #495AFF 62.82%, #495AFF 62.82%); */
        background-color: black;
        /* opacity: 0.1; */
        box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.25);
        border-radius: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        font-size: 12px;
        color: white;
        position: relative;
        top: 4%;
    }

    .Case_Hero_Header .textbox .hero_desc {
        font-size: 18px;
        width: 100%;
        line-height: 35px;
        color: #5C5C5C;
        position: relative;
        top: 2%;
    }
  
.Case_Hero_Header .hero_img {
    width: 100%;
    height: 100%;
    /* background-color: aqua; */
    background-image: url(./icons/case_studies_header_svg.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 107%;
    position: relative;
    left: 0%;
    display: none;
    pointer-events: none;
}


}

.Case_Hero_Header {
    width: 90%;
    min-height: 80vh;
    /* background-color: rebeccapurple; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.Case_Hero_Header .hero_img {
    width: 100%;
    height: 100%;
    /* background-color: aqua; */
    background-image: url(./icons/case_studies_header_svg.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 107%;
    position: relative;
    left: 0%;
}

.Case_Hero_Header .textbox {
    min-width: 50%;
    height: 75%;
    /* background-color: royalblue; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.Case_Hero_Header .textbox .hero_title {
    font-size: 45px;
    width: 70%;
}

.Case_Hero_Header .textbox .hero_title .select {
    font-weight: 700;
    background: linear-gradient(132.47deg, #0ACFFE -1.24%, #495AFF 62.82%, #495AFF 62.82%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.Case_Hero_Header .textbox .hero_desc {
    font-size: 18px;
    min-width: 60%;
    line-height: 35px;
    color: #5C5C5C;
    margin-right: 30%;
}

.Case_Hero_Header .textbox .hero_desc .highlight {
    color: black;
    font-weight: 800;
    font-style: italic;
}


.Case_Hero_Header .textbox .CTA {
    min-width: 50%;
    min-height: 15%;
    /* background: linear-gradient(132.47deg, #0ACFFE -1.24%, #495AFF 62.82%, #495AFF 62.82%); */
    background-color: black;
    /* opacity: 0.1; */
    box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.25);
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 12px;
    color: white;
    margin-top: 2%;
}

.Case_Hero_Header .textbox .CTA h1 {
    font-weight: 800;
}