@media (max-width: 1000px) {
    
    .Sucess_Steps {
        width: 80%;
        height:200vh;
        /* background-color: aqua; */
        position: relative;
        bottom: 3.5%;
    }

    .Sucess_Steps .headerContainer {
        width: 100%;
        height: 20%;
        /* background-color: blue; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    .Sucess_Steps .headerContainer .titleBox {
        min-width: 50%;
        width: 100%;
        height: 50%;
        /* background-color: red; */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }
    
    .Sucess_Steps .headerContainer .titleBox .hero_title {
        text-align: center;
    }

    .Sucess_Steps .headerContainer .titleBox .hero_desc {
        margin: 10%;
        text-align: center;
        width: 90%;
    }

    .Sucess_Steps .steps_container {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
        bottom: 8%;
    }

    .Sucess_Steps .steps_container .step_colm {
        min-width: 48%;
        width: 48%;
        min-height: 17%;
        height: 17%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        /* background-color: aquamarine; */
        position: relative;
        right: 5%;
        margin-bottom: 10rem;
    }


    .Sucess_Steps .headerContainer .icon {
        width: 100%;
        height: 50%;
        background-image: url(./icons/sucess_steps_svg.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        top: 40%;
        display: none;
    }

    .Sucess_Steps .headerContainer .titleBox .CTA {
        min-width: 60%;
        width: 100%;
        min-height: 27%;
        height: 5%;
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 60px;
        text-decoration: none;
    }

    .Sucess_Steps .steps_container .step_colm .iconBox {
        width: 15%;
        height: 95%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #D5EBFF;
        /* background-color: rebeccapurple; */
        border-radius: 360px;
        opacity: 0;
    }
}

.Sucess_Steps {
    width: 80%;
    min-height: 140vh;
    /* background-color: aqua; */
}

.Sucess_Steps .headerContainer {
    width: 100%;
    max-height: 50%;
    height: 50%;
    /* background-color: blue; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Sucess_Steps .headerContainer .titleBox {
    min-width: 50%;
    height: 50%;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.Sucess_Steps .headerContainer .titleBox .hero_title {
    font-size: 40px;
    font-weight: 800;
}

.Sucess_Steps .headerContainer .titleBox .hero_desc {
    font-size: 17px;
    min-width: 80%;
    line-height: 35px;
    color: #5C5C5C;
    font-weight: 600;
    width: 80%;
    position: relative;
    bottom: 5%;
}

.Sucess_Steps .headerContainer .titleBox .CTA {
    min-width: 60%;
    height: 27%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 60px;
    text-decoration: none;
}

.Sucess_Steps .headerContainer .titleBox .CTA h1 {
    color: white;
    font-size: 18px;
    font-weight: 800;
    text-decoration: none;
}

.Sucess_Steps .headerContainer .icon {
    min-width: 50%;
    width: 50%;
    max-height: 100%;
    height: 100%;
    background-image: url(./icons/sucess_steps_svg.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    left: 5%;
    bottom: 5%;
}

.Sucess_Steps .steps_container {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    /* background-color: rebeccapurple; */
}

.Sucess_Steps .steps_container .step_colm {
    min-width: 48%;
    width: 48%;
    min-height: 17%;
    height: 17%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* background-color: aquamarine; */
}

.Sucess_Steps .steps_container .step_colm .iconBox {
    width: 15%;
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #D5EBFF;
    /* background-color: rebeccapurple; */
    border-radius: 360px;
}

.Sucess_Steps .steps_container .step_colm .textbox {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    /* background-color: blue; */
}

.Sucess_Steps .steps_container .step_colm .textbox .title {
   font-size: 21px;
   font-weight: 800;
}

.Sucess_Steps .steps_container .step_colm .textbox .subtitle {
    font-size: 16px;
    color: #535353;
    font-weight: 600;
    width: 85%;
    line-height: 25px;
}

.Sucess_Steps .steps_container .step_colm .iconBox .icon.discovery {
    width: 90%;
    height: 90%;
    background-image: url(./icons/discovery_call_svg.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.Sucess_Steps .steps_container .step_colm .iconBox .icon.strat_audit {
    width: 50%;
    height: 50%;
    background-image: url(./icons/strategy_audit_svg.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.Sucess_Steps .steps_container .step_colm .iconBox .icon.test_period {
    width: 40%;
    height: 40%;
    background-image: url(./icons/test_period_svg.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.Sucess_Steps .steps_container .step_colm .iconBox .icon.optmization {
    width: 40%;
    height: 40%;
    background-image: url(./icons/optimization_svg.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.Sucess_Steps .steps_container .step_colm .iconBox .icon.project_report {
    width: 50%;
    height: 50%;
    background-image: url(./icons/project_success_report_svg.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.Sucess_Steps .steps_container .step_colm .iconBox .icon.in_touch {
    width: 50%;
    height: 50%;
    background-image: url(./icons/stay_in_touch_svg.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}