@media (max-width: 1000px) {

    .Success_Stories {
        width: 90%;
        min-height: 300vh;
        height: 580vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        /* background-color: rebeccapurple; */
        position: relative;
        top: 0.5%;
        margin-bottom: 5%;
    }

    .Success_Stories .title_box .dividerTop {
       opacity: 0;
    }

    .Success_Stories .title_box {
        width: 100%;
        height: 10rem;
        /* background-color: aqua; */
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 22px;
        text-align: center;
        position: relative;
        left: 20%;
        /* bottom: 3%; */
        /* margin-bottom: 5%; */
    }

    .Success_Stories .story_container .success_colm {
        min-width: 30%;
        width: 100%;
        height: 8%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        /* background-color: green; */

    }

    .Success_Stories .story_container .success_colm .logo {
        width: 100%;
        height: 25%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        /* background-color: yellow; */
        background-position: center;
        background-size: 60%;
        background-repeat: no-repeat;
        position: relative;
        top: 5%;
    }

    .Success_Stories .story_container {
        width: 100%;
        height: 100rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        /* background-color: red; */
        /* margin-bottom: ; */
        margin-bottom: 125rem;
    }

}

.Success_Stories {
    width: 90%;
    min-height: 300vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* background-color: rebeccapurple; */
}

.Success_Stories .title_box {
    width: 100%;
    max-height: 10%;
    /* background-color: aqua; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 22px;
}

.Success_Stories .title_box .dividerTop {
    width: 70%;
    height: 2px;
    background-color: #e3e3e3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 360px;
    position: relative;
    top: 5%;
}

.Success_Stories .story_container {
    width: 100%;
    max-height: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    /* background-color: red; */
    position: relative;
    bottom: 20%;
}

.Success_Stories .story_container .success_colm {
    min-width: 30%;
    height: 23%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* background-color: green; */
    margin-bottom: 10%;
}

.Success_Stories .story_container .success_colm .logo {
    width: 100%;
    height: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* background-color: yellow; */
    background-position: center;
    background-size: 60%;
    background-repeat: no-repeat;
}

.Success_Stories .story_container .success_colm .ROA_title {
    font-size: 16px;
    color: #636363;
    margin: 1rem;
}

.Success_Stories .story_container .success_colm .ROA {
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 1rem;
}

.Success_Stories .story_container .success_colm .budget_title {
    font-size: 18px;
    color: #636363;
    margin-bottom: 1rem;
}

.Success_Stories .story_container .success_colm .budget {
    font-size: 30px;
    font-weight: 800;
    margin-bottom: 1rem;
}

.Success_Stories .story_container .success_colm .revenue_title {
    font-size: 20px;
    color: #636363;
    margin-bottom: 1rem;
}

.Success_Stories .story_container .success_colm .revenue {
    font-size: 36px;
    font-weight: 800;
}

.Success_Stories .story_container .success_colm .logo.airbnb {
   background-image: url(../Home/icons/airbnb_svg.svg);
}

.Success_Stories .story_container .success_colm .logo.fitbit {
    background-image: url(../Home/icons/fitbit_svg.svg);
 }

.Success_Stories .story_container .success_colm .logo.twistedtea {
    background-image: url(../Home/icons/twistedtea_svg.svg);
}

.Success_Stories .story_container .success_colm .logo.LAfitness {
    background-image: url(../Home/icons/LAfitness_svg.svg);
}

.Success_Stories .story_container .success_colm .logo.tripadvisor {
    background-image: url(../Home/icons/tripadvisor_svg.svg);
}

.Success_Stories .story_container .success_colm .logo.trustpilot {
    background-image: url(../Home/icons/trustpilot_svg.svg);
}

.Success_Stories .story_container .success_colm .logo.wizementoring {
    background-image: url(./icons/wizementoring_svg.svg);
    background-size: 90%;
}

.Success_Stories .story_container .success_colm .logo.urbanpods {
    background-image: url(./icons/urbanpods_svg.svg);
    background-size: 60%;
}

.Success_Stories .story_container .success_colm .logo.freezvon {
    background-image: url(./icons/freezvon_svg.svg);
    background-size: 55%;
}

.Success_Stories .story_container .success_colm .logo.corebuy {
    background-image: url(./icons/corebuy_svg.svg);
}

.Success_Stories .story_container .success_colm .logo.luxuryrealtor {
    background-image: url(./icons/luxuryrealtor_svg.svg);
    background-size: 44%;
}


.Success_Stories .story_container .success_colm .logo.masterclass {
    background-image: url(../Home/icons/masterclass_svg.svg);
    background-size: 35%;
}

/* color assignments: */

.Success_Stories .story_container .success_colm .revenue .amount.airbnb {
    color: #EB5757;
 }
 
.Success_Stories .story_container .success_colm .revenue .amount.fitbit {
    color: #56CCF2;
  }
 
  .Success_Stories .story_container .success_colm .revenue .amount.twistedtea {
    color: #1219B0;
 }
 
 .Success_Stories .story_container .success_colm .revenue .amount.LAfitness {
     color: #F2C94C;
 }
 
 .Success_Stories .story_container .success_colm .revenue .amount.tripadvisor {
     color: #6FCF97;
 }
 
 .Success_Stories .story_container .success_colm .revenue .amount.trustpilot {
     color: #27AE60;
 }
 
 .Success_Stories .story_container .success_colm .revenue .amount.wizementoring {
    color: #f2b04c;
 }
 
 .Success_Stories .story_container .success_colm .revenue .amount.freezvon {
     color: rgb(34, 137, 255);
 }
 
 .Success_Stories .story_container .success_colm .revenue .amount.corebuy {
    color: limegreen;
 }
 
 .Success_Stories .story_container .success_colm .revenue .amount.luxuryrealtor {
    color: rgb(152, 115, 22);
 }
 
 
 .Success_Stories .story_container .success_colm .revenue .amount.masterclass {
     color: #EB5757;
 }