@media (max-width: 1000px) {

    .FAQ {
        width: 80%;
        max-height: 90vh;
        height: 1vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        /* background-color: rebeccapurple; */
        position: relative;
        bottom: 2.5%;
        display: none;
        opacity: 0;
        pointer-events: none;
    }


.FAQ .dividerBottom {
    width: 100%;
    height: 2px;
    background-color: #e3e3e3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 360px;
    opacity: 0;
}

.FAQ .title_box .dividerTop {
    width: 90%;
    height: 2px;
    background-color: #e3e3e3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 360px;
    position: relative;
    top: 5%;
    opacity: 0;
}

    .FAQ {
        width: 80%;
        min-height: 90vh;
        height: 250vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        /* background-color: rebeccapurple; */
    }

    .FAQ .FAQ_container .FAQ_colm {
        width: 100%;
        height: 10%;
        background-color: #F5F5F5;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        border-radius: 15px;
        text-decoration: none;
        margin-bottom: 5%;
    }

    .FAQ .FAQ_container .FAQ_colm .icon {
        opacity: 0;
    }

    .FAQ .FAQ_container .FAQ_colm .question {
        text-align: center;
        position: relative;
        right: 5%;
    }

    .FAQ .FAQ_container .FAQ_colm .answer {
        text-align: center;       
        }

        .FAQ .title_box .title {
            position: relative;
            left: 38%
        }
        

        .FAQ .FAQ_container {
            position: relative;
            bottom: 6%;
        }  
}

@media (min-width: 1000px) {

    .FAQ {
        position: relative;
        top: 0%;
    }

}

.FAQ {
    width: 80%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* background-color: rebeccapurple; */
    position: relative;
    bottom: 2.5%;
}

.FAQ .title_box {
    width: 100%;
    height: 10%;
    /* background-color: aqua; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 22px;
}

.FAQ .title_box .dividerTop {
    width: 90%;
    height: 2px;
    background-color: #e3e3e3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 360px;
    position: relative;
    top: 5%;
    /* opacity: 0; */
}

.FAQ .FAQ_container {
    width: 100%;
    height: 80%;
    /* background-color: red; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.FAQ .FAQ_container .FAQ_colm {
    width: 48%;
    height: 45%;
    background-color: #F5F5F5;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 15px;
    text-decoration: none;
}

.FAQ .FAQ_container .FAQ_colm .questionBox {
    width: 95%;
    height: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* background-color: aqua; */
}

.FAQ .FAQ_container .FAQ_colm .questionBox .icon {
    width: 5%;
    height: 100%;
    /* background-color: hotpink; */
    background-image: url(./icons/FAQ_icon.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    left: 3.5%;
}

.FAQ .FAQ_container .FAQ_colm .questionBox .question {
    width: 88%;
    height: 100%;
    /* background-color: hotpink; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #494949;
    font-size: 18px;
}

.FAQ .FAQ_container .FAQ_colm .answer {
    position: relative;
    /* margin-right: 6%; */
    font-size: 15px;
    max-width: 90%;
    height: 80%;
    line-height: 22px;
    font-weight: 600;
    color: #313131;
    font-style: italic;
    /* background-color: antiquewhite; */
}

.FAQ .dividerBottom {
    width: 100%;
    height: 2px;
    background-color: #e3e3e3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 360px;
    opacity: 0;
}