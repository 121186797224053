@font-face {
    font-family: appBlack;
    src: url(./Poppins/Poppins-Black.ttf);
}

@font-face {
    font-family: appExtraBold;
    src: url(./Poppins/Poppins-ExtraBold.ttf);
}

@font-face {
    font-family: appBold;
    src: url(./Poppins/Poppins-Bold.ttf);
}

@font-face {
    font-family: appMedium;
    src: url(./Poppins/Poppins-Medium.ttf);
}

@font-face {
    font-family: appLight;
    src: url(./Poppins/Poppins-Light.ttf);
}

@font-face {
    font-family: appExtraLight;
    src: url(./Poppins/Poppins-ExtraLight.ttf);
}